<template>
  <div class="home">
      <!-- <div class="logo"></div> -->
      <div class="topper">
        <div class="i_01 col"></div>
        <div class="i_02 col"></div>
        <div class="i_03 col"></div>
      </div>
  <div id="accordionBotonera" class="accordion botonera">
  <button @click="redirectMenu()">CARTA</button>
  <button class="withImg" onclick="window.open('https://wa.me/+5491159919880', '_blank')">
                
                <div class="img-container">
                    <img src="@/assets/icons/Whatsapp.svg">
                </div>
                RESERVAS
            </button>
  <div class=" withImg">
    <h2 class="accordion-header" id="headingEventos">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEventos" aria-expanded="false" aria-controls="collapseEventos">
        EVENTOS
      </button>
    </h2>
    <div id="collapseEventos" class="accordion-collapse collapse" aria-labelledby="headingEventos" data-bs-parent="#accordionBotonera">
      <div >
        <button class="eventos" onclick="window.open('https://wa.me/+5491164696997', '_blank')">
          <img src="@/assets/icons/Whatsapp.svg"> Eventos sociales y empresariales
        </button>
        <button class="" @click="downloadFile()">
          <img src="@/assets/icons/pdf-file-2.svg"> + INFO
        </button>
      </div>
    </div>
  </div>

  <button class="withImg" onclick="window.open('https://www.instagram.com/cobra.ba', '_blank')">
                <div class="img-container">
                    <img src="@/assets/icons/Instagram.svg">
                </div>
                INSTAGRAM
            </button>
            <button style="text-wrap: nowrap;" class="withImg" onclick="window.open('https://maps.app.goo.gl/hm41Y1D2AyMAVe9U7', '_blank')">
                <div class="img-container">
                    <img src="@/assets/icons/maps.svg">
                </div>
                ¿CÓMO LLEGAR?
            </button>
  <div class="withImg">
    <h2 class="accordion-header" id="headingPlaylists">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePlaylists" aria-expanded="false" aria-controls="collapsePlaylists">
        PLAYLISTS
      </button>
    </h2>
    <div id="collapsePlaylists" class="accordion-collapse collapse" aria-labelledby="headingPlaylists" data-bs-parent="#accordionBotonera">
      <div>
        <button class="" onclick="window.open('https://open.spotify.com/playlist/46Z7tRbqn9KIrMDxSjJ83p?si=911e7ba94e7d4bc9', '_blank')">Cobra Bar</button>
        <button class="" onclick="window.open('https://open.spotify.com/playlist/77JlgKOWRIvO8IxaY3zF2j?si=651ffb7ab8104007', '_blank')">1.0</button>
        <button class="" onclick="window.open('https://open.spotify.com/playlist/5kbi71s1aKJvOhbJW207HN?si=b15b44ca1223432b', '_blank')">2.0</button>
        <button class="" onclick="window.open('https://open.spotify.com/playlist/3Y25RSQyOXlwoAV9htWgMJ?si=5d82e9b97f6640c5', '_blank')">3.0</button>
        <button class="" onclick="window.open('https://open.spotify.com/playlist/3Z8ZMV92iKr4litYGTrVkK?si=e97267f555544204', '_blank')">4.0</button>
        <button class="" onclick="window.open('https://open.spotify.com/playlist/35a4oIPOcp5mUyJxJtu1YY?si=0003637473264e12', '_blank')">5.0</button>
        <button class="" onclick="window.open('https://open.spotify.com/playlist/3KWSVOm8Xh0qBYk4AW7jZc?si=a2b675bf69714d72', '_blank')">Café</button>
      </div>
    </div>
  </div>
  
</div>
        
    </div>
</template>


<script>
  
export default {
  name: 'HomePage',
  components: {  },
  data() {
    return {
    };
  },
  methods: {
    redirectMenu(){
        this.$router.push("/menu");
    },
    downloadFile(){
        const fileUrl = '/docs/PPT_COBRA.pdf';
        const link = document.createElement('a');
        link.style = 'display:none;';
        link.href = fileUrl;
        link.download = 'PPT_COBRA.pdf'; // Specify the file name here
        document.body.appendChild(link); // Append the link to the body
        link.click(); // Trigger a click event
        document.body.removeChild(link); // Remove the link after downloading
    }
    
  },
  mounted() {
  const accordionSections = document.querySelectorAll('.accordion-collapse');
  accordionSections.forEach(section => {
    section.addEventListener('shown.bs.collapse', function () {
      section.scrollIntoView({ behavior: 'smooth', block: 'end' });
    });
  });
}
};
</script>